import React from "react";
import PageWrapper from "Commons/PageWrapper";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Arrow from "Images/home_arrow.svg";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles.scss";

const clientsCarousel = [
  <StaticImage className="image" src="../../images/clients/1_galicia.png" />,
  <StaticImage className="image" src="../../images/clients/2_bpn.png" />,
  <StaticImage className="image" src="../../images/clients/3_bcra.png" />,
  <StaticImage className="image" src="../../images/clients/4_santafe.png" />,
  <StaticImage className="image" src="../../images/clients/5_brinks.png" />,
  <StaticImage className="image" src="../../images/clients/6_bbva.png" />,
  <StaticImage className="image" src="../../images/clients/7_prosegur.png" />,
  <StaticImage className="image" src="../../images/clients/8_bancoprovincia.png" />,
  <StaticImage className="image" src="../../images/clients/9_bancocorrientes.png" />,
  <StaticImage className="image" src="../../images/clients/10_macro.png" />
]

const CarouselSlide = ({ to = "", image, text = "" }) => (
  <div className="home--cover">
    {image}
    <div className="home--text">
      <div className="_center">
        <Container>
          <h1 className="home--text--title">{text}</h1>
          <Link to={to} className="home--text--more-info">
            VER MÁS <span className="_accent-text">→</span>
          </Link>
        </Container>
      </div>
    </div>
  </div>
);

const Home = () => {
  return (
    <PageWrapper>
      <Carousel
        autoPlay
        className="home-top-carousel"
        infiniteLoop
        showArrows={false}
        interval={3000}
        showIndicators={false}
        showThumbs={false}
        showStatus={false}
      >
        <CarouselSlide
          image={
            <StaticImage
              src="../../images/home/home-cover.jpg"
              loading="eager"
              transformOptions={{ quality: 100 }}
              placeholder="blurred"
            />
          }
          to="/blindajes-360"
          text="Recibí la protección y seguridad profesional que estás buscando."
        />
        <CarouselSlide
          image={
            <StaticImage
              src="../../images/home/home-cover-certifications.jpg"
              loading="eager"
              transformOptions={{ quality: 100 }}
              placeholder="blurred"
            />
          }
          to="/nosotros"
          text="Optar por un fabricante con las certificaciones adecuadas garantiza tu seguridad."
        />
        <CarouselSlide
          image={
            <StaticImage
              src="../../images/home/home-cover-truck.jpg"
              loading="eager"
              transformOptions={{ quality: 100 }}
              placeholder="blurred"
            />
          }
          to="/industria-de-defensa"
          text="Unidades Pensadas y fabricadas para UNA exigencia extrema."
        />
      </Carousel>
      <section className="home--01">
        <div className="home--01--container _center">
          <Container>
            <Row>
              <Col sm={3}>
                <StaticImage src="../../images/home/home-aerea.png" loading="eager" />
              </Col>
              <Col sm={7}>
                <div className="home--01--text">
                  <h2 className="home--title _sub-heading">
                    <span className="home--floating-number">01</span>
                    Líderes en Blindaje
                    <br />
                    Hace 55 años
                    <StaticImage  className="home--floating-certificate" src="../../images/tuv_certified.png" />
                  </h2>
                  <p className="home--paragraph">
                    Somos una empresa líder en Argentina con más de 55 años, formada por un grupo
                    de profesionales especializados en el diseño y producción de vehículos
                    blindados y elementos de seguridad para las fuerzas armadas, empresas privadas
                    y civiles.
                    <br />
                    <br />
                    Cada producto que se desarrolla en nuestra planta sigue las estrictas normas
                    del Sistema de Gestión y Calidad ISO 9001.
                  </p>
                  <Link className="_link" to="/nosotros">
                    VER MÁS
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Arrow className="home--01--arrow" />
      </section>
      <section className="home--02">
        <Container>
          <div className="home--02--title-container _center">
            <h2 className="home--title _sub-heading">
              <span className="home--floating-number">02</span>
              Vehículos Blindados
            </h2>
          </div>
          <div className="home--02--images-container _center">
            <div className="home--02--blindajes home--02--blindajes--antivandalica">
              <h3>Protección Antivandálica</h3>
              <StaticImage src="../../images/home/home-antivandalica.jpg"  aspectRatio={16/10} imgStyle={{objectFit: 'contain' }}/>
              <div className="home--02--blindajes--popup">
                <h4>Protección Antivandálica</h4>
                <p>
                  V- Hard, así denominamos a nuestro conjunto de vidrios para la protección anti
                  vandálica.
                  <br />
                  Son una excelente solución para niveles de riesgo moderados.
                </p>
                <Link to="/blindaje-estrategico" className="_link">
                  VER MÁS
                </Link>
              </div>
            </div>
            <div className="home--02--blindajes home--02--blindajes--360">
              <h3>Blindaje 360</h3>
              <StaticImage src="../../images/home/home-blindaje.jpg" aspectRatio={16/10} imgStyle={{objectFit: 'contain'}} />
              <div className="home--02--blindajes--popup">
                <h4>Blindaje 360</h4>
                <p>
                  Así llamamos al blindaje total del vehículo, lo que en el rubro se llama opaco
                  (chapa) y transparente (vidrios).
                  <br />
                  Se recomienda este tipo de blindaje para las personas que buscan más protección o
                  están más expuestos al peligro diario
                </p>
                <Link to="/blindajes-360" className="_link">
                  VER MÁS
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="home--03 home--text-section first">
        <div className="_center">
          <Container>
            <Row>
              <Col sm={5} offset={{ sm: 1 }} className="home--text-section--text">
                <h2 className="home--title _sub-heading">
                  <span className="home--floating-number">03</span>
                  INDUSTRIA DE DEFENSA
                </h2>
                <p className="home--paragraph">
                  Ofrecemos diferentes unidades para las fuerzas armadas de seguridad nacional y
                  de países limítrofes. Pensadas y fabricadas para un uso de extrema exigencia,
                  con diferentes características técnicas según el caso.
                  <br />
                  Los niveles balísticos de estas unidades son RB3, RB4, RB5.
                  <div className="home--03--lists">
                    <ul>
                      <li>Camión GRUPCOM-R</li>
                      <li>Camioneta A-FENIX</li>
                      <li>Valla de seguridad BLOCK 180</li>
                    </ul>
                    <ul>
                      <li>Puestos móviles blindados</li>
                      <li>Torre vigía móvil BUHO HT</li>
                      <li>Torre vigía móvil BUHO II</li>
                    </ul>
                  </div>
                </p>
                <Link to="/industria-de-defensa" className="_link">
                  VER MÁS
                </Link>
              </Col>
              <Col sm={5} offset={{ sm: 1 }}>
                <StaticImage src="../../images/home/home-truck.png" />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="home--04 home--text-section last">
        <div className="_center">
          <Container>
            <Row>
              <Col sm={5}>
                <StaticImage src="../../images/home/home-truck-2.png" />
              </Col>
              <Col sm={6} offset={{ sm: 1 }}>
                <h2 className="home--title _sub-heading">
                  <span className="home--floating-number">04</span>
                  ENTIDADES BANCARIAS Y FINANCIERAS
                </h2>
                <p className="home--paragraph">
                  Desde sus principios hasta el día de hoy, Interservit brindó servicio a las
                  principales entidades financieras y bancarias. <br />
                  Servicios:
                  <ul>
                    <li>Transporte de caudales</li>
                    <li>Seguridad bancaria</li>
                    <li>Bancos móviles</li>
                  </ul>
                </p>
                <Link to="/entidades-bancarias" className="_link">
                  VER MÁS
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="home--05">
        <div className="_center">
          <Visible xs>
            <Carousel
              autoPlay
              infiniteLoop
              centerMode
              selectedItem={2}a
              showArrows={false}
              interval={1500}
              centerSlidePercentage={75}
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
            >
              {clientsCarousel.map(client => client)}
            </Carousel>
          </Visible>
          <Hidden xs>
            <Carousel
              autoPlay
              infiniteLoop
              centerMode
              selectedItem={2}
              showArrows={false}
              interval={1500}
              centerSlidePercentage={20}
              showIndicators={false}
              showThumbs={false}
              showStatus={false}
            >
              {clientsCarousel.map(client => client)}
            </Carousel>
          </Hidden>
        </div>
      </section>
    </PageWrapper>
  );
};

export default Home;
